<template>
    <div style="display:flex;flex-direction:column;height: 100%;" v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-alert :title="connectStatus" v-if="connectStatus" center show-icon type="error" :closable="false">
        </el-alert>
        <div v-if="active == 0">
            <van-cell-group inset>
                <van-cell title="判别电位值" :value="model32.panBieDianWeiZhi"></van-cell>
                <van-field v-model.number="sheZhiPanBieDianWeiZhi" label-width="150" label="设置判别电位值" placeholder="请输入判别电位值" input-align="right" />
            </van-cell-group>
            <van-button type="info" block style="margin-top: 50px;" @click="saveToDevice">保存到设备</van-button>

        </div>
        <div v-else>
            <van-field label="编号" label-width="150" v-model="model_print.bianHao"></van-field>
            <van-field label="试件时间" label-width="150" :value="model_print.shiJian" readonly is-link @click="selectDatetime"> </van-field>
            <van-field label="试件质量" label-width="150" v-model="model_print.shiJianZhiLiang"></van-field>
            <van-field label="AgNO3溶液浓度(mol/L)" label-width="150" v-model="model_print.rongYeNongDu_AgNO3"></van-field>
            <van-field label="空白溶液体积(mL)" label-width="150" v-model="model_print.kongBaiRongYeTiJi"></van-field>
            <van-field label="AgNO3溶液体积(mL)" label-width="150" v-model="model_print.rongYeTiJi_AgNO3"></van-field>
            <van-field label="氯离子质量分数(%)" label-width="150" v-model="model_print.lvLiZiZhiLiangFenShu"></van-field>
            <van-button type="info" block @click="sendPrintCMD">打印</van-button>
        </div>
        <div style="height:50px"></div>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="desktop-o">操作</van-tabbar-item>
            <van-tabbar-item icon="sign">打印</van-tabbar-item>
        </van-tabbar>
        <van-popup v-model="showDatetimePicker" position="bottom">
            <van-datetime-picker v-model="pickerDate" @cancel="showDatetimePicker = false" @confirm="confirmDatetime"></van-datetime-picker></van-popup>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import moment from "moment";
Vue.use(Vant);
export default {
    props: ["serialNumber"],
    data() {
        let that = this;
        return {
            loading: false,
            pickerDate: new Date(),
            showDatetimePicker: false,
            active: 0,
            connectStatus: "",
            mtf: {},
            sheZhiPanBieDianWeiZhi: null,
            model_print: {
                serialNumber: that.serialNumber,
                bianHao: "",
                shiJian: "",
                shiJianZhiLiang: "",
                rongYeNongDu_AgNO3: "",
                kongBaiRongYeTiJi: "",
                rongYeTiJi_AgNO3: "",
                lvLiZiZhiLiangFenShu: "",
            },
            model32: { panBieDianWeiZhi: null },
        };
    },
    methods: {
        selectDatetime() {
            let that = this;
            that.showDatetimePicker = true;
            if (that.model_print.shiJian) {
                that.pickerDate = moment(that.model_print.shiJian).toDate();
            }
        },
        confirmDatetime(v) {
            let that = this;
            that.model_print.shiJian = moment(v).format("YYYY-MM-DD HH:mm:SS");
            that.showDatetimePicker = false;
        },
        sendPrintCMD() {
            let that = this;
            if (!that.model_print.bianHao || !that.model_print.shiJian || !that.model_print.shiJianZhiLiang || !that.model_print.rongYeNongDu_AgNO3 || !that.model_print.kongBaiRongYeTiJi || !that.model_print.rongYeTiJi_AgNO3 || !that.model_print.lvLiZiZhiLiangFenShu) {
                that.$message({ type: "error", message: "有空值" });
                return;
            }
            that.loading = true;
            that.axios.post("llz001_Manage/SendPrintCMD", that.model_print).then((response) => {
                that.loading = false;
                if (response.data.code == 101) {
                    that.$message({ type: "success", message: "发送打印命令成功" });
                } else if (response.data.code == 201) {
                    that.$message({ type: "error", message: "设备已离线，请检查设备是否开机或网络是否可用" });
                    that.offlineDialog = true;
                } else if (response.data.code == 401) {
                    that.$message({ type: "error", message: "发送异常" });
                }
            });
        },

        saveToDevice() {
            let that = this;
            that.websocketsend(
                JSON.stringify({
                    deviceType: "LLZ001",
                    serialNumber: this.serialNumber.toUpperCase(),
                    command: "33",
                    data: JSON.stringify({ panBieDianWeiZhi: that.sheZhiPanBieDianWeiZhi })
                })
            );
        },
        //这一部分是后面加进去的。
        initWebSocket() {
            const wsuri = process.env.VUE_APP_WebSocketURL1;
            this.websock = new WebSocket(wsuri);
            this.websock.onmessage = this.websocketonmessage;
            this.websock.onopen = this.websocketonopen;
            this.websock.onerror = this.websocketonerror;
            this.websock.onclose = this.websocketclose;
        },
        websocketonopen() {
            //连接建立之后执行send方法发送数据
            this.websocketsend(
                JSON.stringify({
                    deviceType: "LLZ001",
                    serialNumber: this.serialNumber.toUpperCase(),
                    command: "32",
                })
            );
        },
        websocketonerror() {
            //连接建立失败重连
            this.initWebSocket();
        },
        websocketonmessage(e) {
            console.log(e);
            let that = this;
            //数据接收
            that.connectStatus = "";
            that.mtf = JSON.parse(e.data);

            if (that.mtf.code == 101) {
                that.model32 = that.mtf.data;
            } else {
                that.connectStatus = "未连接,请配置设备WIFI并连上网络";
            }
            setTimeout(() => {
                this.websocketsend(
                    JSON.stringify({
                        deviceType: "LLZ001",
                        serialNumber: this.serialNumber.toUpperCase(),
                        command: "32",
                    })
                );
            }, 200);
        },
        websocketsend(Data) {
            //数据发送
            this.websock.send(Data);
        },
        websocketclose(e) {
            //关闭
            console.log("断开连接", e);
            this.connectStatus = "服务未启动";
        },
    },
    mounted() {
        let that = this;
        that.initWebSocket();
    }
};
</script>

<style></style>